import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import {
  generateFigureDescriptionPromise,
  refreshFiguresPromise,
} from 'app/office-document';
import {
  figureType,
  getLocalStorage,
  updateFigures,
} from 'engine/localStorageHelper';
import { StatusCodes, StatusManager } from 'engine/models';

import { RouterContextType } from 'views/App/App';
import * as classes from 'views/Figures/Figures.scss';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import TabHeader from 'components/TabHeader/TabHeader';
import Button from 'components/Button/Button';
import Progress from 'components/Progress/Progress';
import FigureItem from 'components/FigureItem/FigureItem';
import { UIStrings } from 'app/UIStrings';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DndContext } from '@dnd-kit/core';

const Figures = () => {
  const navigate = useNavigate();

  const [claims, setClaims] = useState<string[]>([]);
  const [figures, setFigures] = useState<figureType[]>([]);

  const [progress, setProgress] = useState<number>();
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusCode, setStatusCode] = useState<StatusCodes>();
  const [typeOfAction, setTypeOfAction] = useState<
    'refresh' | 'generate' | 'complete'
  >(null);

  const [openItems, setOpenItems] = useState<{[key: string]: boolean}>({});
  const [errorItems, setErrorItems] = useState<{[key: string]: boolean}>({});

  const statusManager: StatusManager = {
    setProgress: (value) => setProgress(value),
    setStatusMessage: (message) => setStatusMessage(message),
    setStatusCode: (code) => {
      setStatusCode(code);

      if (code === StatusCodes.Unauthorized) {
        return navigate('/index.html', { replace: true });
      }

      if (code === StatusCodes.Success) {
        const claims = getLocalStorage().claims;
        setClaims(claims);
      }
    },
  };

  const { status, setStatus }: RouterContextType = useOutletContext();

  /*
    * Updates the progress when the figures array changes.
    */
  useEffect(() => {
    updateProgress('complete');
  }, [figures]);

  /*
    * Updates the progress when the status code changes.
    */
  useEffect(() => {
    if (statusCode === StatusCodes.Success) {
      updateProgress(typeOfAction);
    }
  }, [statusCode]);

  /*
    * Loads the figures and claims from the local storage
    * when the component mounts.
    */
  useEffect(() => {
    const localStorage = getLocalStorage();
    const figures = localStorage.figures;
    const claims = localStorage.claims;

    setFigures(figures);
    setClaims(claims);
  }, []);

  /**
   * Updates the progress based on the type of action.
   * @param type - The type of action to update the progress
   * @returns void
   */
  const updateProgress = (type: 'refresh' | 'generate' | 'complete') => {
    const completeFigures = figures.filter((figure) => figure.complete);

    // TODO - implement progress calculation if needed
    if (type === 'refresh') {
    }

    if (type === 'generate') {
      return setStatus((prevStatus) => {
        return { ...prevStatus, figures: 100, references: 100 };
      });
    }

    if (type === 'complete') {
      return setStatus((prevStatus) => {
        if (completeFigures.length === 0) {
          return { ...prevStatus, figures: 0 };
        }

        if (completeFigures.length === figures.length) {
          return { ...prevStatus, figures: 50 };
        }

        return { ...prevStatus, figures: 25 };
      });
    }
  };

  /**
   * Checks if a given figure number already exists in the figures array.
   *
   * @param figureNumber - The figure number to check for duplicates.
   * @returns A boolean indicating whether the figure number is a duplicate (true) or not (false).
   */
  const checkDuplicateFigure = (figureNumber: string) => {
    const indexOfDuplicateFigure = figures.findIndex(
      (figure) => figure.figureNumber === figureNumber
    );

    return indexOfDuplicateFigure > -1 && figures.length > 1;
  };

  /**
   * Renumbers all figures sequentially.
   * This function creates a new array of figures with updated figure numbers
   * and updates the local storage with the new figures.
   */
  const renumberFigures = () => {
    const newFigures: figureType[] = [];
    for (let i = 1; i <= figures.length; i++) {
      newFigures.push({
        ...figures[i - 1],
        figureNumber: i.toString(),
      });
    }

    updateFiguresInLocalStorage(newFigures);
  };

  /**
   * Refreshes the figures by calling the refreshFiguresPromise function.
   * Sets the type of action to 'refresh'.
   */
  const refresh = () => {
    refreshFiguresPromise(statusManager);
    setTypeOfAction('refresh');
  };

  /**
   * Generates figure descriptions by calling the generateFigureDescriptionPromise function.
   * Sets the type of action to 'generate'.
   */
  const generateFigureDescription = () => {
    generateFigureDescriptionPromise(statusManager);
    setTypeOfAction('generate');
  };

  // TODO - implement abort method
  const abortHandler = () => {};

  /**
   * Handles the toggle open state for a figure.
   * @param figureId - The ID of the figure to toggle the open state for.
   */
  const handleToggleOpen = (figureId: string) => {
    setOpenItems(prevOpenItems => ({
      ...prevOpenItems,
      [figureId]: !prevOpenItems[figureId]
    }));
  };

  /**
   * Sets the error state for a figure.
   * @param figureId - The ID of the figure to set the error state for.
   * @param hasError - A boolean indicating whether the figure has an error (true) or not (false).
   */
  const handleSetError = (figureId: string, hasError: boolean) => {
    setErrorItems(prevErrorItems => ({
      ...prevErrorItems,
      [figureId]: hasError
    }));
  };


  /**
   * Creates a new custom figure and adds it to the existing figures.
   * @param title - The title of the new figure to be created.
   */
  const createCustomFigure = () => {
    const newFigures: figureType[] = [
      ...figures,
      {
        id: `${crypto.randomUUID()}`,
        figureNumber: getNewFiguresNumber(),
        description: '',
        claimIds: [],
        complete: false,
      },
    ];

    updateFiguresInLocalStorage(newFigures);
  };

  /**
   * Generates a new figure number based on the existing figures.
   *
   * @returns A string representing the new figure number.
   */
  const getNewFiguresNumber = () => {
    if (figures.length === 0) return '1';

    const numberPattern = /\d+/g;

    const formattedFigures = figures.map((figureItem) => {
      const onlyNumbers = figureItem.figureNumber.match(numberPattern);
      const formattedFigureNumber = onlyNumbers ? onlyNumbers.join('') : '0';

      return {
        ...figureItem,
        figureNumber: formattedFigureNumber,
      };
    });

    const maxFigureNumber =
      Math.max(
        ...formattedFigures.map(
          (figureItem: figureType) => +figureItem.figureNumber
        )
      ) + 1;

    return maxFigureNumber.toString();
  };

  /**
   * Updates an existing figure with new information.
   * @param figureToUpdate - The figure object to be updated.
   * @param newTitle - The new title for the figure.
   * @param newDescription - The new description for the figure.
   * @param newClaimIds - An array of new claim IDs associated with the figure.
   */
  const updateFigureInLocalStorage = (
    figureToUpdate: figureType,
    newNumber: string,
    newDescription: string,
    newClaimIds: number[]
  ) => {
    const isComplete =
      newNumber && newDescription.trim() && newClaimIds.length > 0;

    const indexOfFigure = figures.findIndex(
      (figure) => figure.id === figureToUpdate.id
    );
    const newFigures = [...figures];
    newFigures.splice(indexOfFigure, 1, {
      id: figureToUpdate.id,
      figureNumber: newNumber,
      description: newDescription.trim(),
      claimIds: newClaimIds,
      complete: isComplete,
    });

    updateFiguresInLocalStorage(newFigures);
  };

  /**
   * Deletes a specified figure from the list of figures.
   * @param figureToDelete - The figure object to be deleted.
   */
  const deleteFigure = (figureToDelete: figureType) => {
    const filteredFigures: figureType[] = figures.filter(
      (figure) => figure.id !== figureToDelete.id
    );
    updateFiguresInLocalStorage(filteredFigures);
  };

  /**
   * Updates the figures in the local storage and updates the component state.
   * @param newFigures - The new array of figures to be stored.
   */
  const updateFiguresInLocalStorage = (newFigures: figureType[]) => {
    setFigures(newFigures);
    updateFigures(newFigures);
  };

  /**
   * Handles the end of a drag operation for figure items.
   *
   * @param event - The drag end event object containing information about the drag operation.
   * @param event.active - The active (dragged) item.
   * @param event.over - The item over which the active item was dropped.
   */
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over) return;
    if (active.id === over.id) return;

    const oldIndex = figures.findIndex(
      (figure) => figure.id === active.id
    );
    const newIndex = figures.findIndex(
      (figure) => figure.id === over.id
    );

    const newFigures: figureType[] = arrayMove(
      figures,
      oldIndex,
      newIndex
    );
    updateFiguresInLocalStorage(newFigures);
  };

  return (
    <div>
      {(statusCode === 0 || statusCode === 1) && <DisabledContainer />}

      <div className={classes.container}>
        <TabHeader
          title={UIStrings.figures.figures_title}
          description={UIStrings.figures.figures_description}
          color="black"
          hasProgress={true}
          progress={status.figures}
          refresh={refresh}
        />

        <div className={classes.main}>
          <div>
            <div className={classes.titleWrapper}>
              {UIStrings.figures.figures_title}
              <div onClick={renumberFigures} className={classes.renumber}>
                <div
                  className={`${classes.icon} ${classes.renumberIcon}`}
                ></div>
                {UIStrings.figures.figures_renumber}
              </div>
            </div>

            <div className={classes.figuresList}>
              <DndContext onDragEnd={handleDragEnd}>
                <SortableContext
                  items={figures}
                  strategy={verticalListSortingStrategy}
                >
                {figures.map((figure: figureType) => (
                  <FigureItem
                    key={`${figure.id}.${figure.figureNumber}`}
                    figure={figure}
                    claims={claims}
                    deleteFigure={deleteFigure}
                    updateFigureInLocalStorage={updateFigureInLocalStorage}
                    checkDuplicateFigure={checkDuplicateFigure}
                    isOpen={openItems[figure.id] || false}
                    isError={errorItems[figure.id] || false}
                    onToggleOpen={() => handleToggleOpen(figure.id)}
                    onSetError={(hasError) => handleSetError(figure.id, hasError)}
                  />
                ))}
                </SortableContext>
              </DndContext>

              <Button
                title={UIStrings.figures.figures_add}
                color="transparent"
                size="big"
                icon="add"
                onClick={() => createCustomFigure()}
              />
            </div>
          </div>

          <div className={classes.generateFiguresButton}>
            <Button
              title={UIStrings.figures.figures_generate}
              color="blue"
              size="big"
              onClick={generateFigureDescription}
            />
          </div>
        </div>

        <Progress
          progress={progress}
          message={statusMessage}
          code={statusCode}
          canAbort={true}
          abortOperation={abortHandler}
        />
      </div>
    </div>
  );
};

export default Figures;
