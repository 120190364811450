// extracted by mini-css-extract-plugin
var _1 = "tmbY8eq_ohPEf3Pf5YD2";
var _2 = "KeuUKamCuzCVC5McMGbx";
var _3 = "CrcTk6PlpDwSaj5UGg3h";
var _4 = "vaeHMb2fBGF5P4T2YhGm";
var _5 = "MMmBsSuuo4TSvQELaqjR";
var _6 = "Xgf8eo_IGBvqqW4pMOkX";
var _7 = "nYpssNuDer6NMRvbR3Lt";
var _8 = "SG3RNuko7SD1LLR1gSKf";
export { _1 as "container", _2 as "figuresList", _3 as "generateFiguresButton", _4 as "icon", _5 as "main", _6 as "renumber", _7 as "renumberIcon", _8 as "titleWrapper" }
