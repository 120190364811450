import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@fluentui/react-components';
import { CSS } from '@dnd-kit/utilities';

import { figureType } from 'engine/localStorageHelper';
import * as classes from 'components/FigureItem/FigureItem.scss';
import { UIStrings } from 'app/UIStrings';
import { useSortable } from '@dnd-kit/sortable';
import InputError from '../InputError/InputError';

type FigureItemProps = {
  figure: figureType;
  claims: string[];
  isOpen: boolean;
  isError: boolean;
  onToggleOpen: () => void;
  onSetError: (hasError: boolean) => void;
  deleteFigure?: (figure: figureType) => void;
  updateFigureInLocalStorage?: (
    figure: figureType,
    newFigureNumber: string,
    newDescription: string,
    newClaimIds: number[]
  ) => void;
  checkDuplicateFigure?: (figureNumber: string) => boolean;
};

const FigureItem = ({
  figure,
  claims,
  isOpen,
  isError,
  onToggleOpen,
  onSetError,
  deleteFigure,
  updateFigureInLocalStorage,
  checkDuplicateFigure,
}: FigureItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: figure.id });
  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };
  const prevFigureNumberRef = useRef<string>(figure.figureNumber);

  const [figureNumber, setFigureNumber] = useState<string>(figure.figureNumber);
  const [description, setDescription] = useState<string>(figure.description);
  const [claimIds, setClaimIds] = useState<number[]>(figure.claimIds);

  /**
   * Updates the figure in local storage whenever claimIds changes.
   */
  useEffect(() => {
    if (figure && updateFigureInLocalStorage) {
      handleUpdateFigureInLocalStorage();
    }
  }, [claimIds]);

  /**
   * Updates the figure in local storage with the current figureNumber, description, and claim IDs.
   */
  const handleUpdateFigureInLocalStorage = () => {
    updateFigureInLocalStorage(figure, figureNumber, description, claimIds);
    setFigureNumber(figureNumber);
    setDescription(description.trim());
  };

  /**
   * Attempts to update the figure number.
   * - If the number hasn't changed, no update occurs.
   * - If the number is a duplicate, an error state is set, and the update is aborted.
   * - Otherwise, updates the figure number and stores it locally.
   * @param newFigureNumber - The proposed new figure number.
   * @returns {boolean} True if the update was successful, false otherwise.
   */
  const handleUpdateFigureNumber = (newFigureNumber: string) => {
    if (prevFigureNumberRef.current === newFigureNumber) {
      return;
    }

    if (checkDuplicateFigure(newFigureNumber)) {
      onSetError(true);
      return;
    }

    onSetError(false);
    updateFigureInLocalStorage(figure, newFigureNumber, description, claimIds);
    prevFigureNumberRef.current = newFigureNumber;
    setFigureNumber(newFigureNumber);
  };

  /**
   * Toggles the selection of a claim for the current figure and updates the figure in local storage.
   * @param {React.MouseEvent} e - The mouse event object.
   * @param {number} claimId - The ID of the claim to be toggled.
   */
  const handleUpdateClaimIds = (e: React.MouseEvent<HTMLDivElement>, claimId: number) => {
    const indexOfClaimId = claimIds.indexOf(claimId);
    const newClaimIds = [...claimIds];

    if (indexOfClaimId >= 0) {
      (e.currentTarget as HTMLDivElement).style.backgroundColor = '#FFF';
      (e.currentTarget as HTMLDivElement).style.color = '#020305';
      newClaimIds.splice(indexOfClaimId, 1);
    } else {
      (e.currentTarget as HTMLDivElement).style.backgroundColor = '#1267B4';
      (e.currentTarget as HTMLDivElement).style.color = '#FFF';
      newClaimIds.push(claimId);
    }

    setClaimIds(newClaimIds);
    handleUpdateFigureInLocalStorage();
  };

  return (
    <div className={classes.container} style={style} ref={setNodeRef} {...attributes}>
      <div className={classes.titleWrapper}>
        <div className={`${classes.icon} ${classes.dragIcon}`} {...listeners}></div>
        <div className={classes.titleText}> {'Fig. '} {/* Replace input with div */}</div>
        <div className={classes.options}>
            <div
              className={`${classes.icon} ${classes.trashIcon}`}
              onClick={() =>
                deleteFigure({
                  id: figure.id,
                  figureNumber,
                  description,
                  claimIds: [],
                  complete: figure.complete,
                })
              }
            ></div>

            <input
              type="text"
              minLength={1}
              maxLength={5}
              id="figureNumber"
              className={classes.figureNumberInput}
              value={figureNumber}
              onBlur={(e) => handleUpdateFigureNumber(e.target.value)}
              onChange={(e) => setFigureNumber(e.target.value)}
            />
            {isOpen ? (
              <div
                className={`${classes.icon} ${classes.arrowDown}`}
                onClick={onToggleOpen}
              ></div>
            ) : (
              <div
                className={`${classes.icon} ${classes.arrowUp}`}
                onClick={onToggleOpen}
              ></div>
            )}
          </div>
      </div>

      {isOpen && (
        <div className={classes.descriptionWrapper}>
          <textarea
            placeholder={UIStrings.figure_item.figure_description_placeholder}
            value={description}
            onBlur={handleUpdateFigureInLocalStorage}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>

          {claims.length > 0 && (
            <div className={classes.claimsWrapper}>
              {claims.map((claim, index) => (
                <Tooltip
                  key={index}
                  content={claim}
                  relationship="description"
                  positioning="below"
                  appearance="inverted"
                >
                  <div
                    className={classes.claimItem}
                    onClick={(e) => handleUpdateClaimIds(e, index)}
                    style={{
                      color: claimIds.includes(index) ? '#FFFFFF' : '#020305',
                      backgroundColor: claimIds.includes(index)
                        ? '#1267B4'
                        : '#FFFFFF',
                    }}
                  >
                    {index + 1}
                  </div>
                </Tooltip>
              ))}
            </div>
          )}
        </div>
      )}
      {isError && (
        <InputError errorMessage={UIStrings.figures.figures_duplicate_error} />
      )}
    </div>
  );
};

export default FigureItem;
