import { useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { referenceType } from 'engine/localStorageHelper';
import * as classes from 'components/ReferenceItem/ReferenceItem.scss';
import InputError from 'components/InputError/InputError';
import { UIStrings } from 'app/UIStrings';

type ReferenceItemProps = {
  reference: referenceType;
  isNew?: boolean;
  cancel?: () => void;
  createCustomReference?: (title: string) => void;
  deleteReference?: (reference: referenceType) => void;
  updateReferenceInLocalStorage?: (
    reference: referenceType,
    newTitle: string,
    newId: string
  ) => void;
  checkDuplicateReference?: (refNumber: string) => boolean;
};

const ReferenceItem = ({
  reference,
  isNew,
  cancel,
  createCustomReference,
  deleteReference,
  updateReferenceInLocalStorage,
  checkDuplicateReference,
}: ReferenceItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: reference.id });
  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const prevTitleRef = useRef<string>(reference.title);
  const prevReferenceNumberRef = useRef<string>(reference.referenceNumber);

  const [title, setTitle] = useState<string>(reference.title);
  const [referenceNumber, setReferenceNumber] = useState<string>(
    reference.referenceNumber
  );

  const [isError, setIsError] = useState<boolean>(false);

  /**
   * Updates the title of a reference.
   * @param newTitle - The new title to be set for the reference.
   * @returns void
   */
  const handleUpdateTitle = (newTitle: string) => {
    if (prevTitleRef.current === newTitle) {
      return;
    }

    updateReferenceInLocalStorage(reference, newTitle, referenceNumber);

    prevTitleRef.current = newTitle.trim();
    setTitle(newTitle.trim());
  };

  /**
   * Updates the reference number of a reference.
   * @param newReferenceNumber - The new reference number to be set.
   * @returns void
   */
  const handleUpdateReferenceNumber = (newReferenceNumber: string) => {
    if (prevReferenceNumberRef.current === newReferenceNumber) {
      return;
    }

    if (checkDuplicateReference(newReferenceNumber)) {
      return setIsError(true);
    }

    setIsError(false);
    updateReferenceInLocalStorage(reference, title, newReferenceNumber);
    prevReferenceNumberRef.current = newReferenceNumber;
    setReferenceNumber(newReferenceNumber);
  };

  /**
   * Creates a new reference with the current title.
   * @returns void
   */
  const handleCreateReference = () => {
    if (!title.trim()) return;

    cancel();
    createCustomReference(title.trim());
    setTitle(title.trim());
  };

  return (
    <div
      className={classes.container}
      style={style}
      ref={setNodeRef}
      {...attributes}
    >
      {isNew ? (
        <div className={`${classes.titleWrapper} ${classes.newTitle}`}>
          <input
            type="text"
            id="title"
            className={classes.titleInput}
            autoFocus
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <div className={classes.options}>
            <div
              className={`${classes.icon} ${classes.dismissIcon}`}
              onClick={cancel}
            ></div>
            <div
              className={`${classes.icon} ${classes.applyIcon}`}
              onClick={handleCreateReference}
            ></div>
          </div>
        </div>
      ) : (
        <div>
          <div className={classes.titleWrapper}>
            <div
              className={`${classes.icon} ${classes.dragIcon}`}
              {...listeners}
            ></div>

            <input
              type="text"
              id="title"
              className={classes.titleInput}
              value={title}
              onBlur={(e) => handleUpdateTitle(e.target.value)}
              onChange={(e) => setTitle(e.target.value)}
            />

            <div
              className={`${classes.icon} ${classes.trashIcon}`}
              onClick={() =>
                deleteReference({
                  id: reference.id,
                  title: title,
                  rootWord: '',
                  searchWholePhrase: false,
                  leftPhrase: '',
                  referenceNumber: referenceNumber,
                })
              }
            ></div>

            <input
              type="text"
              minLength={1}
              maxLength={5}
              id="referenceNumber"
              className={classes.referenceNumberInput}
              value={referenceNumber}
              onBlur={(e) => handleUpdateReferenceNumber(e.target.value)}
              onChange={(e) => setReferenceNumber(e.target.value)}
            />
          </div>

          {isError && (
            <InputError errorMessage={UIStrings.references.reference_duplicate_error} />
          )}
        </div>
      )}
    </div>
  );
};

export default ReferenceItem;
