/* global Word */

import { tempStorage } from 'app/index';
import {
  DISTANCES_FOUND_PERCENT_THRESHOLD,
  fetchAllDistances,
  fetchAbstracts,
  fetchCommon,
  fetchDetailedClaim,
  fetchDistances,
  fetchFigure,
  fetchGenerateReport,
  fetchReferences,
  fetchRoots,
  fetchSearchStemmed,
  fetchTermLookup,
  fetchTermsDescriptions,
  fetchUpdateDescription,
} from './apiHelpers';
import {
  deleteBlockParagraphs,
  deleteBlocksMarkupParagraphs,
  deleteErrorMarkers,
  deleteReferenceMarkers,
  deleteReferencesWithBookmarks,
  getMultiBlocksParagraphs,
  getMultiBlocksParagraphsWithProgress,
  getParagraphBookmarks,
  getParagraphTextWithoutReferences,
  insertBlockParagraphs,
  insertBookmarkReference,
  insertParagraphAfterParId,
  removeParagraphById,
} from './documentProxy';
import {
  checkDefinitionLocalStorage,
  consistencyType,
  definitionType,
  deleteDynamicDefinitions,
  getLocalStorage,
  getTestErrors,
  localStorageType,
  referenceType,
  statusType,
  suggestionType,
  updateClaimDescriptions,
  updateClaims,
  updateLocalStorage,
} from './localStorageHelper';
import {
  FillZoneType,
  PromiseStatus,
  StatusCodes,
  StatusManager,
  defaultPromiseStatus,
} from './models';
import { UIStrings } from 'app/UIStrings';
import { RouterContextType } from 'app/views/App/App';
import { useOutletContext } from 'react-router-dom';

/**
 * Creates and returns a new PromiseStatus object with default values.
 *
 * This function initializes a PromiseStatus object using the default values
 * from the defaultPromiseStatus constant. It's useful for setting up initial
 * state for promise-related operations.
 *
 * @returns {PromiseStatus} A new PromiseStatus object with default values set.
 */
const getDefaultPromiseStatus = () => {
  const promiseStatus: PromiseStatus = {
    progress: defaultPromiseStatus.progress,
    aborted: defaultPromiseStatus.aborted,
    bulkTaskCount: defaultPromiseStatus.bulkTaskCount,
    currentTaskId: defaultPromiseStatus.currentTaskId,
  };
  return promiseStatus;
};

/**
 * Calculates and sets the total progress for a bulk operation.
 *
 * This function updates the progress of a bulk task based on the local progress
 * of the current task and the overall number of tasks in the bulk operation.
 *
 * @param promiseStatus - The current status of the promise, including bulk task information.
 * @param localProgress - The progress of the current individual task (0-100).
 *
 * @returns void - This function doesn't return a value, but updates the progress
 *                 property of the promiseStatus object.
 */
const setTotalProgressInBulk = (
  promiseStatus: PromiseStatus,
  localProgress: number
) => {
  let progress = 0;
  if (promiseStatus.bulkTaskCount <= 1) {
    progress = localProgress;
  } else {
    progress =
      localProgress / promiseStatus.bulkTaskCount +
      (promiseStatus.currentTaskId / promiseStatus.bulkTaskCount) * 100;
  }

  promiseStatus.progress = progress;
};

/**
 * Generates a status message for bulk operations, appending task progress information if applicable.
 *
 * @param promiseStatus - The current status of the promise, including information about bulk tasks.
 * @param statusMessage - The base status message to be potentially modified.
 * @returns A string containing the status message, potentially appended with bulk task progress information.
 */
const getStatusMessageInBulk = (
  promiseStatus: PromiseStatus,
  statusMessage: string
) => {
  let bulkTaskStr = '';
  if (promiseStatus.bulkTaskCount > 1) {
    bulkTaskStr =
      ' (' +
      (promiseStatus.currentTaskId + 1).toString() +
      '/' +
      promiseStatus.bulkTaskCount +
      ')';
  }
  return statusMessage + bulkTaskStr;
};

/**
 * Asynchronously generates and inserts descriptions for all claims in the document.
 *
 * This function fetches detailed descriptions for each claim, updates the document
 * with these descriptions, and manages the progress and status of the operation.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param promiseStatus - An object that tracks the progress and state of the promise.
 * @param resolve - A function to resolve the promise with the operation's result.
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 */
const fillAllClaimsDescriptions = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  if (promiseStatus.aborted) {
    resolve({ aborted: true });
    return;
  }

  let error = '';
  let httpCode = 0;

  setTotalProgressInBulk(promiseStatus, 0);
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(
    getStatusMessageInBulk(
      promiseStatus,
      UIStrings.statusbar.generating_descriptions
    )
  );

  const claimsMap = await reloadClaimsToLocalStorage();

  const len = claimsMap.claims.length;
  const claimDescriptions = [];

  for (let i = 0; i < claimsMap.claims.length; i++) {
    if (promiseStatus.aborted) {
      break;
    }
    const intKey = claimsMap.idx[i];

    setTotalProgressInBulk(promiseStatus, Math.round((i / len) * 100));
    statusManager.setProgress(promiseStatus.progress);

    const resp = await fetchDetailedClaim(
      claimsMap.claims[i],
      intKey,
      true,
      false
    );
    httpCode = resp.httpCode;
    if (!resp.error) {
      console.log('detailed claim:' + resp.data);
      claimDescriptions.push(resp.data);
    } else {
      error = 'detailed claim fetch error:' + resp.error;
      console.log(error);
    }
  }

  if (!promiseStatus.aborted) {
    if (!error) {
      await insertBlockParagraphs(
        'common',
        claimDescriptions,
        true,
        true,
        true,
        claimsMap.claims
      );

      updateClaimDescriptions(claimDescriptions);

      console.log('loop finished');
      setTotalProgressInBulk(promiseStatus, 100);
      statusManager.setProgress(promiseStatus.progress);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.description_created);
    } else {
      if (httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.description_failed);
      }
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

/**
 * Asynchronously fills a specified zone in the document with generated content.
 *
 * This function generates and inserts content for different parts of a document
 * (e.g., summary, title) based on the specified fill zone type. It manages the
 * progress, status updates, and error handling throughout the process.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param fillZoneType - Specifies which zone of the document to fill (e.g., SummaryTop, SummaryBottom, Title).
 * @param promiseStatus - An object that tracks the progress and state of the promise.
 * @param resolve - A function to resolve the promise with the operation's result.
 * @returns {Promise<void>} A promise that resolves when the fill operation is complete.
 */
const fillZone = async (
  statusManager: StatusManager,
  fillZoneType: FillZoneType,
  promiseStatus: PromiseStatus,
  resolve
) => {
  if (promiseStatus.aborted) {
    resolve({ aborted: true });
    return;
  }

  let taskTitle = '';
  let progressTaskTitle = '';
  let completedTaskTitle = '';
  let failedTaskTitle = '';
  let instructionType = '';
  let blockName = '';
  let beginning = true;
  let cleanup = true;

  // Prepare Title
  switch (fillZoneType) {
    case FillZoneType.SummaryTop:
      instructionType = 'summary-top';
      blockName = 'summaryTop';
      cleanup = true;
      beginning = true;
      taskTitle = UIStrings.tasks.intro_title;
      progressTaskTitle = UIStrings.tasks.intro_progress;
      completedTaskTitle = UIStrings.tasks.intro_completed;
      failedTaskTitle = UIStrings.tasks.intro_failed;
      statusManager.currentTaskId = 1;
      break;
    case FillZoneType.SummaryBottom:
      instructionType = 'summary-bottom';
      blockName = 'summaryBottom';
      cleanup = true;
      beginning = true;
      taskTitle = UIStrings.tasks.summary_title;
      progressTaskTitle = UIStrings.tasks.summary_progress;
      completedTaskTitle = UIStrings.tasks.summary_completed;
      failedTaskTitle = UIStrings.tasks.summary_failed;
      statusManager.currentTaskId = 3;
      break;
    case FillZoneType.Title:
      instructionType = 'title';
      blockName = 'title';
      cleanup = true;
      beginning = true;
      taskTitle = UIStrings.tasks.title_title;
      progressTaskTitle = UIStrings.tasks.title_progress;
      completedTaskTitle = UIStrings.tasks.title_completed;
      failedTaskTitle = UIStrings.tasks.title_failed;
      statusManager.currentTaskId = 0;
      break;
    default:
      instructionType = 'title';
      blockName = 'title';
      break;
  }

  console.log(taskTitle);

  setTotalProgressInBulk(promiseStatus, 0);
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(
    getStatusMessageInBulk(promiseStatus, progressTaskTitle)
  );

  const claimsMap = await reloadClaimsToLocalStorage();

  setTotalProgressInBulk(promiseStatus, 50);
  statusManager.setProgress(promiseStatus.progress);

  let claimsText = '';

  for (const [, value] of Object.entries(claimsMap)) {
    if (promiseStatus.aborted) {
      break;
    }
    claimsText += value + '\n';
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  } else {
    const commonResp = await fetchCommon(
      claimsText,
      instructionType,
      'gpt',
      false
    );
    if (!promiseStatus.aborted) {
      if (!commonResp.error) {
        if (fillZoneType === FillZoneType.Title) {
          tempStorage.patentTitle = commonResp.data;
        }

        await insertBlockParagraphs(
          blockName,
          [commonResp.data],
          true,
          beginning,
          cleanup
        );
        setTotalProgressInBulk(promiseStatus, 100);
        statusManager.setProgress(promiseStatus.progress);
        statusManager.setStatusCode(StatusCodes.Success);
        if (
          promiseStatus.currentTaskId == promiseStatus.bulkTaskCount - 1 &&
          promiseStatus.bulkTaskCount > 1
        ) {
          statusManager.setStatusMessage(
            UIStrings.statusbar.all_operations_completed
          );
        } else {
          statusManager.setStatusMessage(completedTaskTitle);
        }

        resolve({ completed: true });
      } else {
        if (commonResp.httpCode === 401) {
          statusManager.setStatusCode(StatusCodes.Unauthorized);
          statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
        } else {
          statusManager.setStatusCode(StatusCodes.Fail);
          statusManager.setStatusMessage(failedTaskTitle);
        }

        resolve({ completed: true });
      }
    }
  }
};

/**
 * Saves a definition to the database and updates the status manager accordingly.
 *
 * This function attempts to save a given definition to the database using an API call.
 * It updates the status manager to reflect the progress and outcome of the operation.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param definition - The definition object to be saved in the database.
 * @param promiseStatus - An object that tracks the progress and state of the promise.
 * @param resolve - A function to resolve the promise with the operation's result.
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 */
const saveDefinitionInDb = async (
  statusManager: StatusManager,
  definition: definitionType,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.inserting_definitions);

  const resp = await fetchUpdateDescription(
    definition.title,
    definition.description
  );

  if (!resp.error) {
    statusManager.setStatusCode(StatusCodes.Success);
    statusManager.setStatusMessage(UIStrings.statusbar.definition_saved);
  } else {
    if (resp.httpCode === 401) {
      statusManager.setStatusCode(StatusCodes.Unauthorized);
      statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
    } else {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(
        UIStrings.statusbar.definition_save_failed
      );
    }
  }
  resolve({ completed: true });
};

/**
 * Refreshes the definitions in the document by fetching new terms and updating existing ones to synchronize the list with the terms in document.
 *
 * This function performs the following tasks:
 * 1. Clears existing dynamic definitions
 * 2. Retrieves all paragraphs from specified sections of the document
 * 3. Fetches term descriptions for each paragraph
 * 4. Updates the local storage with new and existing definitions
 * 5. Checks the presence of each definition in the document
 * 6. Updates the status manager with the progress and result of the operation
 *
 * @param statusManager - Manages and updates the status of the refresh operation
 * @param promiseStatus - Tracks the progress and state of the asynchronous operation
 * @param resolve - Function to resolve the promise with the operation result
 * @returns {Promise<void>} A promise that resolves when the refresh operation is complete
 */
const refreshDefinitions = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  deleteDynamicDefinitions();
  tempStorage.origTerms = [];
  tempStorage.totalTerms = [];
  tempStorage.definitionsFirstInsert = true;
  const localStorage = getLocalStorage();
  let definitions: definitionType[] = localStorage.definitions;
  const searchDefinitions: definitionType[] = [];
  if (!definitions) {
    definitions = [];
  }

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.updating_definition);

  let allParagraphs = await getMultiBlocksParagraphsWithProgress(
    [
      'intro',
      'common',
      'definitions',
      'figures',
      'references',
      'claims',
      'summary',
    ],
    statusManager,
    promiseStatus,
    0,
    70
  );

  const paragraphTexts: string[] = [];
  const descriptionTexts: string[] = [];
  let error = '';
  let httpCode = 0;

  if (!promiseStatus.aborted) {
    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      if (paragraph.text) {
        // Generate the related description
        const defs = await fetchTermsDescriptions(paragraph.text);

        if (!defs.error) {
          // Store original text as parentText
          let parentText = paragraph.text;
          paragraphTexts.push(parentText);

          // Store the related description for later insertion
          for (let j = 0; j < defs.paragraphs.length; j++) {
            if (j > 0) {
              parentText = defs.paragraphs[j - 1];
            }

            searchDefinitions.push({
              title: defs.origTerms[j],
              parentText: parentText,
              description: defs.paragraphs[j],
              checked: false,
              changed: false,
              custom: false,
            });

            console.log('par: ', parentText);
          }
        } else {
          error = defs.error;
          httpCode = defs.httpCode;
          console.error('fetchTermsDescriptions error. ' + defs.error);
          break;
        }
      }
    }

    if (!error) {
      localStorage.definitions = [...searchDefinitions, ...definitions];
      for (let i = 0; i < localStorage.definitions.length; i++) {
        descriptionTexts.push(localStorage.definitions[i].description);
      }

      // Test each definition for checked/unchecked state.
      const distancesResult = await fetchAllDistances(
        paragraphTexts,
        descriptionTexts
      );
      if (!distancesResult.error) {
        for (let i = 0; i < distancesResult.percentage.length; i++) {
          if (
            distancesResult.percentage[i] >= DISTANCES_FOUND_PERCENT_THRESHOLD
          ) {
            localStorage.definitions[distancesResult.indices[i]].checked = true;
          }
        }
        updateLocalStorage(localStorage);
      } else {
        error = distancesResult.error;
        httpCode = distancesResult.httpCode;
      }
    }

    if (error) {
      if (httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.refresh_failed);
      }
    } else {
      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.definitions_updated);
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

/**
 * Checks checkbox and inserts a definition into the document.
 *
 * This function looks for the appropriate location to insert a given definition
 * in the document. If found, it inserts the definition and updates the status.
 *
 * @param statusManager - Manages and updates the status of the operation
 * @param definition - The definition to be checked and potentially inserted
 * @param promiseStatus - Tracks the progress and state of the asynchronous operation
 * @param resolve - Function to resolve the promise with the operation result
 * @returns {Promise<void>} A promise that resolves when the check and insert operation is complete
 */
const checkDefinition = async (
  statusManager: StatusManager,
  definition: definitionType,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.inserting_definitions);

  let allParagraphs = await getMultiBlocksParagraphs([
    'intro',
    'common',
    'definitions',
    'figures',
    'references',
    'claims',
    'summary',
  ]);

  const ids: number[] = [];
  const texts: string[] = [];

  // Iterate through each paragraph
  for (let i = 0; i < allParagraphs.length; i++) {
    const paragraph = allParagraphs[i];

    if (paragraph.text) {
      texts.push(paragraph.text);
      ids.push(i);
    }
  }

  const resp = await fetchTermLookup(texts, definition.title);
  let parentParagraph: Word.Paragraph;

  if (!resp.error) {
    parentParagraph = allParagraphs[ids[resp.index]];
    definition.parentText = parentParagraph.text;
    if (promiseStatus.aborted) {
      resolve({ aborted: true });
    } else {
      let res = false;
      if (parentParagraph) {
        res = await insertParagraphAfterParId(
          parentParagraph.uniqueLocalId,
          definition.description
        );
      }
      statusManager.setProgress(100);
      if (res) {
        checkDefinitionLocalStorage(definition);
        statusManager.setStatusCode(StatusCodes.Success);
        statusManager.setStatusMessage(UIStrings.statusbar.definitions_added);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(
          UIStrings.statusbar.definitions_insert_failed
        );
      }
    }
  } else {
    if (resp.httpCode === 401) {
      statusManager.setStatusCode(StatusCodes.Unauthorized);
      statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
    } else {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(UIStrings.statusbar.definition_not_found);
    }
  }
  resolve({ completed: true });
};

/**
 * Unchecks a definition and attempts to remove it from the document.
 *
 * This function searches for the definition in the document, removes it if found,
 * and updates the status accordingly. It uses distance calculations to identify
 * the most likely location of the definition in the document.
 *
 * @param statusManager - Manages and updates the status of the operation
 * @param definition - The definition to be unchecked and removed
 * @param promiseStatus - Tracks the progress and state of the asynchronous operation
 * @param resolve - Function to resolve the promise with the operation result
 * @returns {Promise<void>} A promise that resolves when the uncheck operation is complete
 */
const uncheckDefinition = async (
  statusManager: StatusManager,
  definition: definitionType,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.removing_definition);

  let allParagraphs = await getMultiBlocksParagraphs([
    'intro',
    'common',
    'definitions',
    'figures',
    'references',
    'claims',
    'summary',
  ]);

  let texts: string[] = [];
  let ids: string[] = [];

  // Iterate through each paragraph
  for (let i = 0; i < allParagraphs.length; i++) {
    const paragraph = allParagraphs[i];

    if (paragraph.text) {
      texts.push(paragraph.text);
      ids.push(paragraph.uniqueLocalId);
    }
  }

  const distancesResponse = await fetchDistances(definition.description, texts);

  if (!distancesResponse.error) {
    const max = Math.max(...distancesResponse.percentage);
    let parId;

    if (max >= DISTANCES_FOUND_PERCENT_THRESHOLD) {
      const index = distancesResponse.percentage.indexOf(max);
      console.log(index);
      if (index >= 0) {
        parId = ids[index];
      }
    }

    if (promiseStatus.aborted) {
      resolve({ aborted: true });
    } else {
      let res = false;
      if (parId) {
        res = await removeParagraphById(parId);
      }

      statusManager.setProgress(100);
      if (res) {
        checkDefinitionLocalStorage(definition);
        statusManager.setStatusCode(StatusCodes.Success);
        statusManager.setStatusMessage(UIStrings.statusbar.definition_removed);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.remove_failed);
      }
      resolve({ completed: true });
    }
  } else {
    if (distancesResponse.httpCode === 401) {
      statusManager.setStatusCode(StatusCodes.Unauthorized);
      statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
    } else {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(UIStrings.statusbar.remove_failed);
    }
    resolve({ completed: true });
  }
};

/**
 * Inserts multiple checked definitions into the document.
 *
 * This function processes an array of definitions, attempting to insert each one into
 * the appropriate location in the document. It handles the insertion process, updates
 * the status, and manages error cases.
 *
 * @param statusManager - Manages and updates the status of the operation
 * @param definitions - An array of definition objects to be checked and inserted
 * @param promiseStatus - Tracks the progress and state of the asynchronous operation
 * @param resolve - Function to resolve the promise with the operation result
 * @returns {Promise<void>} A promise that resolves when all definitions have been processed
 */
const checkMultipleDefinitions = async (
  statusManager: StatusManager,
  definitions: definitionType[],
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.inserting_definitions);

  let fail = true;
  let httpCode = 0;

  for (let d = 0; d < definitions.length; d++) {
    const definition = definitions[d];
    const definitionsLines: string[] = [];

    let allParagraphs = await getMultiBlocksParagraphs([
      'intro',
      'common',
      'definitions',
      'figures',
      'references',
      'claims',
      'summary',
    ]);

    const ids: number[] = [];
    const texts: string[] = [];

    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      if (paragraph.text) {
        texts.push(paragraph.text);
        ids.push(i);
      }
    }

    const resp = await fetchTermLookup(texts, definition.title);
    let parentParagraph: Word.Paragraph;
    httpCode = resp.httpCode;
    if (!resp.error) {
      parentParagraph = allParagraphs[ids[resp.index]];
      if (parentParagraph) {
        definition.parentText = parentParagraph.text;
        if (promiseStatus.aborted) {
          resolve({ aborted: true });
        } else {
          let res = false;

          if (true) {
            res = true;
            definitionsLines.push(definition.description);
          }
          if (res) {
            checkDefinitionLocalStorage(definition);
            fail = false;
          }
        }
        await insertBlockParagraphs(
          'definitions',
          definitionsLines,
          false,
          false,
          tempStorage.definitionsFirstInsert
        );
        tempStorage.definitionsFirstInsert = false;
      }
    } else {
      // Insert definition even if it is not present in text.
      await insertBlockParagraphs(
        'definitions',
        [definition.description],
        false,
        false,
        tempStorage.definitionsFirstInsert
      );
      tempStorage.definitionsFirstInsert = false;
      checkDefinitionLocalStorage(definition);
      fail = false;
    }
    statusManager.setProgress(((d + 1) * 100) / definitions.length);
  }
  // If at least one was checked, then it is Success.
  if (fail) {
    if (httpCode === 401) {
      statusManager.setStatusCode(StatusCodes.Unauthorized);
      statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
    } else {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(UIStrings.statusbar.definition_not_found);
    }
  } else {
    statusManager.setStatusCode(StatusCodes.Success);
    statusManager.setStatusMessage(UIStrings.statusbar.definitions_added);
  }
  resolve({ completed: true });
};

/**
 * Removes multiple unchecked definitions from the document. (not used)
 *
 * This function iterates through the provided definitions, attempts to locate each one
 * in the document, and removes it if found. It updates the status throughout the process
 * and handles both successful and failed removals.
 *
 * @param statusManager - Manages and updates the status of the operation
 * @param definitions - An array of definition objects to be unchecked and removed
 * @param promiseStatus - Tracks the progress and state of the asynchronous operation
 * @param resolve - Function to resolve the promise with the operation result
 * @returns {Promise<void>} A promise that resolves when all definitions have been processed
 */
const uncheckMultipleDefinitions = async (
  statusManager: StatusManager,
  definitions: definitionType[],
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.removing_definition);

  let fail = false;
  let httpCode = 0;

  for (let d = 0; d < definitions.length; d++) {
    const definition = definitions[d];

    let allParagraphs = await getMultiBlocksParagraphs([
      'intro',
      'common',
      'definitions',
      'figures',
      'references',
      'claims',
      'summary',
    ]);

    let texts: string[] = [];
    let ids: string[] = [];

    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      if (paragraph.text) {
        texts.push(paragraph.text);
        ids.push(paragraph.uniqueLocalId);
      }
    }

    const distancesResponse = await fetchDistances(
      definition.description,
      texts
    );
    if (!distancesResponse.error) {
      const max = Math.max(...distancesResponse.percentage);
      let parId;

      if (max >= DISTANCES_FOUND_PERCENT_THRESHOLD) {
        const index = distancesResponse.percentage.indexOf(max);
        console.log(index);
        if (index >= 0) {
          parId = ids[index];
        }
      }

      if (promiseStatus.aborted) {
        resolve({ aborted: true });
      } else {
        let res = false;
        if (parId) {
          res = await removeParagraphById(parId);
        }

        statusManager.setProgress(((d + 1) * 100) / definitions.length);
        if (res) {
          checkDefinitionLocalStorage(definition);
        } else {
          fail = false;
        }
      }
    } else {
      break;
    }
  }
  if (fail) {
    if (httpCode === 401) {
      statusManager.setStatusCode(StatusCodes.Unauthorized);
      statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
    } else {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(UIStrings.statusbar.remove_failed);
    }
  } else {
    statusManager.setStatusCode(StatusCodes.Success);
    statusManager.setStatusMessage(UIStrings.statusbar.definitions_removed);
  }
  resolve({ completed: true });
};

/**
 * Refreshes the references suggestions and updates the local storage.
 *
 * This function processes the document's content, fetches AI-generated references,
 * and updates the local storage with new reference suggestions. It handles the entire
 * process of refreshing references, including status updates and error handling.
 *
 * @param statusManager - An instance of StatusManager used to update the status of the operation.
 * @param promiseStatus - An object tracking the progress and abort state of the asynchronous operation.
 * @param resolve - A function to resolve the promise with the operation result.
 * @returns {Promise<void>} A promise that resolves when the reference refresh operation is complete.
 */
const refreshReferences = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  const localStorage = getLocalStorage();
  const references: referenceType[] = localStorage.references
    ? localStorage.references
    : [];
  const suggestions: suggestionType[] = [];

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.refresh_references);

  let allParagraphs = await getMultiBlocksParagraphsWithProgress(
    ['intro', 'common', 'figures', 'references', 'claims', 'summary'],
    statusManager,
    promiseStatus,
    0,
    70
  );

  const paragraphTexts: string[] = [];

  if (!promiseStatus.aborted) {
    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      if (paragraph.text) {
        // Store original text as parentText
        let parentText = paragraph.text;
        paragraphTexts.push(parentText);
      }
    }
    const wholeText = paragraphTexts.join('\n');
    const aiRefs = await fetchReferences(wholeText);

    if (aiRefs && !aiRefs.error) {
      for (let i = 0; i < aiRefs.references.length; i++) {
        const newSuggestion: suggestionType = {
          title: aiRefs.references[i],
          enabled: true,
        };
        for (let j = 0; j < references.length; j++) {
          if (
            aiRefs.references[i].toLowerCase() ===
            references[j].title.toLowerCase()
          ) {
            newSuggestion.enabled = false;
            break;
          }
        }
        suggestions.push(newSuggestion);
      }

      localStorage.suggestions = suggestions;

      updateLocalStorage(localStorage);
      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.references_updated);
    } else {
      if (aiRefs.httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.refresh_failed);
      }
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

/**
 * Assigns to each reference object the text before the referenced word and returns an array of these phrases.
 *
 * This function processes each reference, finding the root word within its title,
 * and sets the 'leftPhrase' as the substring from the start of the title up to and including the root word.
 *
 * @param refs - An array of reference objects to process
 * @param roots - An array of root words corresponding to each reference
 * @returns An array of left phrases extracted from each reference
 */
const fillLeftPhrases = (refs: referenceType[], roots: string[]) => {
  const leftPhrases: string[] = [];
  for (let i = 0; i < refs.length; i++) {
    const rootStart = refs[i].title.indexOf(roots[i]);
    const lpLen = rootStart + roots[i].length;
    refs[i].leftPhrase = refs[i].title.substring(0, lpLen);
    leftPhrases.push(refs[i].leftPhrase);
  }
  return leftPhrases;
};

/**
 * Updates the root words for references and stores to the local storage.
 *
 * This function fetches root words for reference titles, updates the reference objects
 * with new root words, and determines whether to search for whole phrases based on
 * duplicates and other conditions. It then updates the local storage with the modified
 * reference objects.
 *
 * @returns {Promise<void>} A promise that resolves when the update process is complete.
 */
const updateRoots = async () => {
  const findDuplicates = (arr: string[]) =>
    arr.filter((item, index) => arr.indexOf(item) !== index);

  const localStorage = getLocalStorage();
  // Update root words.
  let refs = localStorage.references;
  const titles: string[] = [];
  refs.forEach((element) => {
    titles.push(element.title);
  });
  const res = await fetchRoots(titles);
  if (!res.error && res.roots.length === refs.length) {
    for (let i = 0; i < res.roots.length; i++) {
      if (!res.roots[i] || res.roots[i].length === 1) {
        res.roots[i] = refs[i].title;
      }
    }

    const leftPhrases = fillLeftPhrases(refs, res.roots);
    const duplicates = [...new Set(findDuplicates(leftPhrases))];
    for (let i = 0; i < res.roots.length; i++) {
      refs[i].rootWord = res.roots[i];
      // We add ref number after the main word only if roots are not duplicated and contains more than 1 word
      refs[i].searchWholePhrase = false;

      if (
        refs[i].rootWord === refs[i].title ||
        duplicates.includes(res.roots[i])
      ) {
        //const leftPhraseDuplicates =
        refs[i].searchWholePhrase = true;
      }
    }

    localStorage.references = refs;
    console.log('Refs with Updated roots:');
    console.log(refs);
    updateLocalStorage(localStorage);
  }
};

/**
 * Analyzes references stored in local storage and prepares a structured result.
 *
 * This function processes the references from local storage, extracting and organizing
 * various pieces of information such as keywords, root words, reference numbers, and
 * a summary of references. It handles special cases where the search phrase might be
 * different from the full title.
 *
 * @returns {Object} An object containing the analysis results with the following properties:
 *   - keywords: An array of strings representing the search phrases for each reference.
 *   - roots: An array of strings containing the root words of each reference.
 *   - references: An object mapping reference keys to their corresponding reference numbers.
 *   - refSummary: An array of strings, each containing a tab-separated reference number and title.
 */
const analyzeReferences = () => {
  const analyzeReferencesResult = {
    keywords: [] as string[],
    roots: [] as string[],
    references: {} as { [id: string]: string },
    refSummary: [] as string[],
  };

  const localStorage = getLocalStorage();

  for (let i = 0; i < localStorage.references.length; i++) {
    let refKey = localStorage.references[i].title;
    if (
      !localStorage.references[i].searchWholePhrase &&
      localStorage.references[i].leftPhrase &&
      /\S/.test(localStorage.references[i].leftPhrase)
    ) {
      refKey = localStorage.references[i].leftPhrase;
    }
    analyzeReferencesResult.keywords.push(refKey);
    analyzeReferencesResult.roots.push(localStorage.references[i].rootWord);

    const idStr = localStorage.references[i].referenceNumber.toString();

    if (analyzeReferencesResult.references[refKey]) {
      analyzeReferencesResult.references[refKey] =
        analyzeReferencesResult.references[refKey] + ', ' + idStr;
    } else {
      analyzeReferencesResult.references[refKey] = idStr;
    }

    analyzeReferencesResult.refSummary.push(
      idStr + '\t' + localStorage.references[i].title
    );
  }

  return analyzeReferencesResult;
};

/**
 * Applies references to the document by updating, inserting, and formatting reference information.
 *
 * This function performs a series of operations including updating root words, deleting existing
 * references, analyzing paragraphs, inserting new references, and updating the document's status.
 * It handles the entire process of applying references to the document, including error handling
 * and progress updates.
 *
 * @param statusManager - An instance of StatusManager used to update the status and progress of the operation.
 * @param promiseStatus - An object tracking the progress and abort state of the asynchronous operation.
 * @param resolve - A function to resolve the promise with the operation result.
 * @returns {Promise<void>} A promise that resolves when the reference application process is complete.
 */
const applyReferences = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  setTotalProgressInBulk(promiseStatus, 0);
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.inserting_references);

  await updateRoots();

  await deleteBlockParagraphs('references');

  setTotalProgressInBulk(promiseStatus, 10);
  statusManager.setProgress(promiseStatus.progress);

  await deleteReferencesWithBookmarks();

  setTotalProgressInBulk(promiseStatus, 20);
  statusManager.setProgress(promiseStatus.progress);

  let allParagraphs = await getMultiBlocksParagraphs(['figures']);

  setTotalProgressInBulk(promiseStatus, 30);
  statusManager.setProgress(promiseStatus.progress);

  let claimsParagraphsCount = 0;

  let claimsParagraphs = await getMultiBlocksParagraphs(['claims']);
  claimsParagraphsCount = claimsParagraphs.length;
  allParagraphs.push(...claimsParagraphs);

  setTotalProgressInBulk(promiseStatus, 40);
  statusManager.setProgress(promiseStatus.progress);

  if (!promiseStatus.aborted) {
    const ares = analyzeReferences();

    const paragraphTexts: string[] = [];

    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      // Store original text
      paragraphTexts.push(paragraph.text);
    }

    const resp = await fetchSearchStemmed(
      paragraphTexts,
      ares.keywords,
      ares.roots
    );

    if (resp.error) {
      if (resp.httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.insert_failed);
      }
    } else {
      setTotalProgressInBulk(promiseStatus, 50);
      statusManager.setProgress(promiseStatus.progress);

      for (let p = 0; p < resp.paragraphs.length; p++) {
        let delta = 0;
        const respPar = resp.paragraphs[p];
        const paragraph = allParagraphs[respPar.parIndex];
        const brackets: boolean =
          respPar.parIndex >= allParagraphs.length - claimsParagraphsCount;

        for (let s = 0; s < respPar.searchResults.length; s++) {
          const refId = ares.references[respPar.searchResults[s].keyword];
          const charsCnt = await insertBookmarkReference(
            paragraph.uniqueLocalId,
            respPar.searchResults[s].startIndex + delta,
            respPar.searchResults[s].endIndex + delta,
            refId,
            brackets
          );
          delta += charsCnt;
        }

        setTotalProgressInBulk(
          promiseStatus,
          60 + p * (40 / resp.paragraphs.length)
        );
        statusManager.setProgress(promiseStatus.progress);
      }

      await insertBlockParagraphs(
        'references',
        ares.refSummary,
        false,
        true,
        true
      );

      setTotalProgressInBulk(promiseStatus, 100);
      statusManager.setProgress(promiseStatus.progress);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.references_inserted);
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

/**
 * Applies abstracts to the document by generating and inserting abstract text.
 *
 * This function retrieves patent numbers from local storage, fetches abstract text using an API call,
 * and inserts the generated abstract into the document. It handles the entire process of applying
 * abstracts, including error handling and progress updates.
 *
 * @param statusManager - An instance of StatusManager used to update the status and progress of the operation.
 * @param promiseStatus - An object tracking the progress and abort state of the asynchronous operation.
 * @param resolve - A function to resolve the promise with the operation result.
 * @returns {Promise<void>} A promise that resolves when the abstract application process is complete.
 */
const applyAbstracts = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.generating_abstracts);

  let error = '';
  let httpCode = 0;
  const patent_numbers: string[] = [];
  const abstract_text: string[] = [];

  if (!promiseStatus.aborted) {
    const localStorage = getLocalStorage();

    console.log(localStorage.abstracts);

    // Create an array with figures with both their name and description input by the user.
    for (let i = 0; i < localStorage.abstracts.length; i++) {
      // Concatenate with a Tab character between title and description
      let item = localStorage.abstracts[i].patentNumber;
      patent_numbers.push(item);
    }

    const resp = await fetchAbstracts(
      patent_numbers,
      false
    );

    // Checks whether the response contains any warnings, which get forwarded from the server.
    // The warning can then be shown for each abstract individual by toggling the 'hasError' flag.
    if (resp.warnings) {
      for (let i = 0; i < localStorage.abstracts.length; i++) {
        for (let j = 0; j < resp.warnings.length; j++) {
          if (resp.warnings[j].PatentNumber == localStorage.abstracts[i].patentNumber) {
            localStorage.abstracts[i].hasError = true;
          }
        }
      }
    }

    console.log(localStorage);
    updateLocalStorage(localStorage);

    if (!resp.error) {
      abstract_text.push(resp.data);
      statusManager.setProgress(75);
    } else {
      error = resp.error;
      httpCode = resp.httpCode;
    }

    if (!error) {
      // First delete the whole figure block, then insert both the descriptions and the fetched figure text
      await insertBlockParagraphs('intro', abstract_text, true, false, true);

      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.abstract_generated);
    } else {
      if (resp.httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.generation_failed);
      }
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

/**
 * Refreshes figures in the local storage by reloading claims and updating the status.
 *
 * This function updates the figures in the local storage by reloading claims from local storage.
 * It manages the progress and status of the operation, handling both successful completion
 * and abortion scenarios.
 *
 * @param statusManager - An instance of StatusManager used to update the status and progress of the operation.
 * @param promiseStatus - An object tracking the progress and abort state of the asynchronous operation.
 * @param resolve - A function to resolve the promise with the operation result.
 * @returns {Promise<void>} A promise that resolves when the figure refresh process is complete or aborted.
 */
const refreshFigures = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.updating_figures);

  if (!promiseStatus.aborted) {
    await reloadClaimsToLocalStorage();
    statusManager.setProgress(100);
    statusManager.setStatusCode(StatusCodes.Success);
    statusManager.setStatusMessage(UIStrings.statusbar.figures_updated);
    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

/**
 * Generates figure descriptions based on stored data and updates the document.
 *
 * This function retrieves figure data from local storage, generates descriptions,
 * fetches additional figure information, and updates the document with the new content.
 * It also manages the progress and status of the operation throughout the process.
 *
 * @param statusManager - An instance of StatusManager used to update the status and progress of the operation.
 * @param promiseStatus - An object tracking the progress and abort state of the asynchronous operation.
 * @param resolve - A function to resolve the promise with the operation result.
 * @returns {Promise<void>} A promise that resolves when the figure description generation process is complete or aborted.
 */
const generateFigureDescription = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.generating_figures);

  let error = '';
  let httpCode = 0;
  const figure_descriptions: string[] = [];
  const figures: string[] = [];

  if (!promiseStatus.aborted) {
    const localStorage = getLocalStorage();

    // Create an array with figures with both their name and description input by the user.
    for (let i = 0; i < localStorage.figures.length; i++) {
      let item = 'Fig. ' + localStorage.figures[i].figureNumber + '\t';
      // Remove trailing "." from figures[i].description if they exist
      const description = localStorage.figures[i].description.replace(
        /\.$/,
        ''
      );

      if (i === localStorage.figures.length - 1 && i > 0) {
        // Last element and more than 1 element, add "." at the end
        item += description + '.';
      } else if (i === localStorage.figures.length - 2 && i > 0) {
        // Second to last element and more than 1 element, add ", and" at the end
        item += description + UIStrings.figures.figures_and;
      } else {
        // For other elements, add "," at the end
        item += description + ',';
      }

      figure_descriptions.push(item);
    }

    for (let i = 0; i < localStorage.figures.length; i++) {
      const figureClaims: string[] = [];
      const ids = localStorage.figures[i].claimIds;
      for (let c = 0; c < ids.length; c++) {
        figureClaims.push(localStorage.claims[ids[c]]);
      }
      const resp = await fetchFigure(
        localStorage.figures[i].figureNumber,
        localStorage.figures[i].description,
        figureClaims
      );
      if (!resp.error) {
        figures.push(resp.data);
        statusManager.setProgress(((i + 1) * 90) / localStorage.figures.length);
      } else {
        error = resp.error;
        httpCode = resp.httpCode;
        break;
      }
    }

    if (!error) {
      // First delete the whole figure block, then insert both the descriptions and the fetched figure text
      await insertBlockParagraphs(
        'figure_descriptions',
        figure_descriptions,
        true,
        true,
        true
      );
      await insertBlockParagraphs('figures', figures, true, true, true);

      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.figures_generated);
    } else {
      if (httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.generation_failed);
      }
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

/**
 * Finalizes the document by removing markers and markup paragraphs.
 *
 * This function performs cleanup operations on the document, including deleting
 * reference markers, error markers, and blocks markup paragraphs. It updates
 * the status throughout the process and resolves the promise upon completion.
 *
 * @param statusManager - An instance of StatusManager used to update the status and progress of the operation.
 * @param promiseStatus - An object tracking the progress and abort state of the asynchronous operation.
 * @param resolve - A function to resolve the promise with the operation result.
 * @returns {Promise<void>} A promise that resolves when the document finalization process is complete or aborted.
 */
const finalizeDocumentConsistency = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.finalizing);

  if (!promiseStatus.aborted) {
    await deleteReferenceMarkers();
    await deleteErrorMarkers();

    await deleteBlocksMarkupParagraphs();

    statusManager.setProgress(100);
    statusManager.setStatusCode(StatusCodes.Success);
    statusManager.setStatusMessage(UIStrings.statusbar.finalized);

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

/**
 * Gathers the numerical status of each function into one directory.
 *
 * This function extracts the status of each function from the local storage and
 * prepares a dictionary with the numerical status values. It also calculates the
 * total status value as the average of all other status values.
 *
 * It also removes the 'consistencyErrorsCount' key from the status dictionary, as
 * this does not indicate the progress status of a function from 0 to 100, but the
 * number of errors which were detected.
 *
 * @param localStorage - The local storage object containing the status dictionary.
 *
 * @returns { [key: string]: number } A dictionary containing the numerical status values of each function.
 */
const prepareStatesForAPI = (localStorage: any): { [key: string]: number } => {

  const statusEntries = Object.fromEntries(
    Object.entries(localStorage.status).map(([key, value]) => [
      key,
      typeof value === 'object' && value !== null && 'tabStatus' in value
        ? value.tabStatus
        : value
    ])
  ) as Record<string, number>;

  const { consistencyErrorsCount, ...filteredStates } = statusEntries;
  const stateValues = Object.values(filteredStates);
  const total = stateValues.reduce((sum, value) => sum + value, 0) / stateValues.length;
  const mappedStates: { [key: string]: number } = {};

  Object.entries(filteredStates).forEach(([key, value]) => {
    const mappedKey = UIStrings.states[key as keyof typeof UIStrings.states] || key;
    mappedStates[mappedKey] = value;
  });
  mappedStates[UIStrings.states.total] = total;

  return mappedStates;
};

/**
 * Generates a PDF report based on consistency data and updates the status accordingly.
 *
 * This function retrieves consistency data from local storage, generates a report
 * using the fetched data, and updates the status manager with the progress and
 * outcome of the report generation process.
 *
 * @param statusManager - An instance of StatusManager used to update the status
 *                        and progress of the report generation operation.
 * @param promiseStatus - An object tracking the progress and abort state of the
 *                        asynchronous operation.
 * @param resolve - A function to resolve the promise with the operation result.
 * @returns {Promise<void>} A promise that resolves when the report generation
 *                          process is complete or aborted.
 */
const generateReport = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.generating_report);

  if (!promiseStatus.aborted) {
    const localStorage: localStorageType = getLocalStorage();
    const consistency: consistencyType[] = localStorage.consistency;
    const states = prepareStatesForAPI(localStorage);

    const dateTime = new Intl.DateTimeFormat(tempStorage.apiLanguage, {
      dateStyle: 'medium',
      timeStyle: 'long',
    }).format(new Date());
    console.log(dateTime);

    const resp = await fetchGenerateReport(
      states,
      consistency,
      tempStorage.patentTitle,
      dateTime
    );

    if (!resp.error) {
      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.report_generated);
      tempStorage.reportId = resp.reportId;
    } else {
      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(UIStrings.statusbar.generation_failed);
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

/**
 * Collects and organizes claims from a series of paragraphs into a structured format.
 *
 * This function processes an array of Word.Paragraph objects, extracting claim information
 * and organizing it into an object with claim indices and full claim texts. It handles
 * multi-paragraph claims and removes reference markers from the claim text.
 *
 * @param claimsParagraphs - An array of Word.Paragraph objects representing the claims section.
 * @returns A promise that resolves to an object containing:
 *   - idx: An array of claim numbers (indices).
 *   - claims: An array of corresponding claim texts.
 */
const collectClaimsMap = async (claimsParagraphs: Word.Paragraph[]) => {
  let currentClaimId = -1;
  let currentClaim = '';
  const idx: number[] = [];
  const claims: string[] = [];

  for (const [, para] of Object.entries(claimsParagraphs)) {
    let paraText = await getParagraphTextWithoutReferences(para.uniqueLocalId);
    const numDotIdx = paraText.indexOf('.');
    if (numDotIdx > 0 && numDotIdx < 3) {
      const claimId = Number(paraText.substring(0, numDotIdx));
      if (!Number.isNaN(claimId)) {
        // Is first line of any claim.
        if (currentClaim) {
          // Push previous claim.
          claims.push(currentClaim);
          idx.push(currentClaimId);
        }
        currentClaimId = claimId;
        currentClaim = '';
      }
      paraText = paraText.substring(numDotIdx + 1);
    }

    if (currentClaimId >= 0) {
      let str = paraText;
      if (str.charAt(0) == '·') {
        str = str.substring(1, str.length);
      }
      str = str.replace(/[\r\n]+/g, ' ');
      str = str.trim() + ' ';

      currentClaim += str;
    }
  }

  if (currentClaim) {
    // Push previous claim.
    claims.push(currentClaim);
    idx.push(currentClaimId);
  }

  return { idx: idx, claims: claims };
};

/**
 * Reloads claims from the document into local storage.
 *
 * This function retrieves the claims paragraphs from the document, processes them into a structured format,
 * updates the claims in local storage, and returns the processed claims map.
 *
 * @returns {Promise<{idx: number[], claims: string[]}>} A promise that resolves to an object containing:
 *   - idx: An array of claim numbers.
 *   - claims: An array of corresponding claim texts.
 */
const reloadClaimsToLocalStorage = async () => {
  const claimsParagraphs = await getMultiBlocksParagraphs(['claims']);
  const claimsMap = await collectClaimsMap(claimsParagraphs);
  updateClaims(claimsMap.claims);

  return claimsMap;
};

/**
 * Finds all occurrences of a substring within a given text and returns their indices.
 *
 * @param text - The main string to search within.
 * @param subs - The substring to search for.
 * @returns An array of numbers representing the starting indices of all occurrences of the substring.
 *          Returns an empty array if no occurrences are found.
 */
const findAllIndices = (text: string, subs: string) => {
  const a: number[] = [];
  let i = -1;
  while ((i = text.indexOf(subs, i + 1)) >= 0) a.push(i);
  return a;
};

export {
  getDefaultPromiseStatus,
  setTotalProgressInBulk,
  getStatusMessageInBulk,
  fillAllClaimsDescriptions,
  fillZone,
  collectClaimsMap,
  saveDefinitionInDb,
  refreshDefinitions,
  checkDefinition,
  uncheckDefinition,
  checkMultipleDefinitions,
  uncheckMultipleDefinitions,
  refreshReferences,
  applyReferences,
  applyAbstracts,
  generateFigureDescription,
  refreshFigures,
  finalizeDocumentConsistency,
  reloadClaimsToLocalStorage,
  generateReport,
  updateRoots,
  analyzeReferences,
  findAllIndices,
};
